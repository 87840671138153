import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Title, Flex } from "../components/system"

const NotFoundPage = () => (
  <Layout>
    <Flex height="100vh" flexDirection="column">
      <Title>I think you're lost</Title>
      <Link to="/">Go Back Home</Link>
    </Flex>
  </Layout>
)

export default NotFoundPage
